"use client";
import Image from "next/image";
import "./footer.css";
import mailIcon from "@/src/assets/images/driverPartner/mail.svg";
import mailIcon2 from "@/src/assets/images/footer/mail.svg";
import locationIcon from "@/src/assets/images/footer/location.svg";
import phoneIcon from "@/src/assets/images/footer/Phone.svg";
import Link from "next/link";
import { useEffect, useState } from "react";
import startup_india from "@/src/assets/images/footer/startup_india.png";
import insta from "@/src/assets/images/footer/insta.png";
import linkedIn from "@/src/assets/images/footer/linkedIn.png";

const Footer = () => {
	const [currentLocation, setCurrentLocation] = useState();
	useEffect(() => {
		if (typeof window !== "undefined") {
			const pathSegments = window.location.pathname.split("/");
			setCurrentLocation(pathSegments[pathSegments.length - 1] || "home");
		}
	}, []);
	function callPhoneNumber(phoneNumber) {
		window.location.href = "tel:" + phoneNumber;
	}
	function sendEmail(emailAddress) {
		window.location.href = "mailto:" + emailAddress;
	}
	//   function showLocationOnMap(coordinates) {
	// 	window.location.href = 'geo:' + coordinates;
	//   }

	return (
		<footer>
			<div
				className="top_shape"
				style={
					currentLocation === "home"
						? { backgroundColor: "#F2F6F7" }
						: {}
				}
			></div>
			<div className="contact-container">
				<div className="gradient-text">GET IN TOUCH</div>
				<div className="mail-container">
					<Image
						className="mail-icon"
						alt="Mail"
						src={mailIcon}
						width={30}
						height={30}
					/>
					<p>We’d love to hear from you</p>
				</div>
				<div className="contact-type-container">
					<Link rel="canonical" href={"/contact-us"}>
						<div className="contact-type">
							Contact us <span>+</span>
						</div>
					</Link>
				</div>
				<img
					src={startup_india.src}
					alt="startup_india"
					className="startup_india"
				/>
			</div>

			<div className="link-container">
				<div className="footer-links-group">
					<Link rel="canonical" href={"/"}>
						{" "}
						<div
							id="home"
							className={`footer-links ${
								currentLocation === "home"
									? "footer-active-link"
									: ""
							}`}
						>
							Home
						</div>
					</Link>
					<Link rel="canonical" href={"/driver-partner"}>
						{" "}
						<div
							id="driver"
							className={`footer-links ${
								currentLocation === "driver-partner"
									? "footer-active-link"
									: ""
							}`}
						>
							Driver
						</div>
					</Link>
					<Link rel="canonical" href={"/vehicles"}>
						{" "}
						<div
							className={`footer-links ${
								currentLocation === "vehicles"
									? "footer-active-link"
									: ""
							}`}
						>
							Vehicle
						</div>
					</Link>
					<Link rel="canonical" href={"/about-us"}>
						{" "}
						<div
							id="about"
							className={`footer-links ${
								currentLocation === "about-us"
									? "footer-active-link"
									: ""
							}`}
						>
							About us
						</div>
					</Link>
					<Link rel="canonical" href={"/privacy-policy"}>
						{" "}
						<div
							id="privacy"
							className={`footer-links ${
								currentLocation === "privacy-policy"
									? "footer-active-link"
									: ""
							}`}
						>
							Privacy Policy
						</div>
					</Link>
				</div>

				<div className="footer_company_info">
					<div>
						<div>
							<span>
								{" "}
								<Image
									src={phoneIcon}
									width={15}
									height={15}
									alt="icon"
								/>{" "}
								Phone Number
							</span>
							<div className="details_container">
								<p
									onClick={() =>
										callPhoneNumber("011-3567-9455")
									}
								>
									+91-8826404013
								</p>
								<div className="Details_underlines"></div>
							</div>
							<div className="details_container">
								<p
									onClick={() =>
										callPhoneNumber("+91-9990204000")
									}
								>
									+91-9990204000
								</p>
								<div className="Details_underlines"></div>
							</div>
						</div>
						<div>
							<span>
								{" "}
								<Image
									src={mailIcon2}
									width={15}
									height={15}
									alt="mail"
								/>{" "}
								Email
							</span>
							<div className="details_container">
								<p
									onClick={() =>
										sendEmail("office@zevoindia.in")
									}
								>
									office@zevoindia.in
								</p>
								<div className="Details_underlines"></div>
							</div>
							<div className="details_container">
								<p
									onClick={() =>
										sendEmail("operations@zevoindia.in")
									}
								>
									operations@zevoindia.in
								</p>
								<div className="Details_underlines"></div>
							</div>
						</div>
					</div>
					<div>
						<span>
							{" "}
							<Image
								src={locationIcon}
								width={15}
								height={15}
								alt="location"
							/>{" "}
							Registered address
						</span>
						<p className="location_p">
							C-10, Lower Ground Floor, South Extension-2, New
							Delhi-110049
						</p>
					</div>
					<div>
						<span>
							{" "}
							<Image
								src={locationIcon}
								width={15}
								height={15}
								alt="location"
							/>{" "}
							Head Office
						</span>
						<p className="location_p">
							601,602,603, Sixth Floor, DLF South Court Mall,
							Saket District Centre, New Delhi-110017
						</p>
					</div>
					<div style={{ paddingTop: "20px" }}>
						<span>
							<svg
								fill="#c2d0d6"
								width="20px"
								height="20px"
								viewBox="0 0 50 50"
							>
								<path d="M8 2L8 6L4 6L4 48L46 48L46 14L30 14L30 6L26 6L26 2 Z M 10 4L24 4L24 8L28 8L28 46L19 46L19 39L15 39L15 46L6 46L6 8L10 8 Z M 10 10L10 12L12 12L12 10 Z M 14 10L14 12L16 12L16 10 Z M 18 10L18 12L20 12L20 10 Z M 22 10L22 12L24 12L24 10 Z M 10 15L10 19L12 19L12 15 Z M 14 15L14 19L16 19L16 15 Z M 18 15L18 19L20 19L20 15 Z M 22 15L22 19L24 19L24 15 Z M 30 16L44 16L44 46L30 46 Z M 32 18L32 20L34 20L34 18 Z M 36 18L36 20L38 20L38 18 Z M 40 18L40 20L42 20L42 18 Z M 10 21L10 25L12 25L12 21 Z M 14 21L14 25L16 25L16 21 Z M 18 21L18 25L20 25L20 21 Z M 22 21L22 25L24 25L24 21 Z M 32 22L32 24L34 24L34 22 Z M 36 22L36 24L38 24L38 22 Z M 40 22L40 24L42 24L42 22 Z M 32 26L32 28L34 28L34 26 Z M 36 26L36 28L38 28L38 26 Z M 40 26L40 28L42 28L42 26 Z M 10 27L10 31L12 31L12 27 Z M 14 27L14 31L16 31L16 27 Z M 18 27L18 31L20 31L20 27 Z M 22 27L22 31L24 31L24 27 Z M 32 30L32 32L34 32L34 30 Z M 36 30L36 32L38 32L38 30 Z M 40 30L40 32L42 32L42 30 Z M 10 33L10 37L12 37L12 33 Z M 14 33L14 37L16 37L16 33 Z M 18 33L18 37L20 37L20 33 Z M 22 33L22 37L24 37L24 33 Z M 32 34L32 36L34 36L34 34 Z M 36 34L36 36L38 36L38 34 Z M 40 34L40 36L42 36L42 34 Z M 32 38L32 40L34 40L34 38 Z M 36 38L36 40L38 40L38 38 Z M 40 38L40 40L42 40L42 38 Z M 10 39L10 44L12 44L12 39 Z M 22 39L22 44L24 44L24 39 Z M 32 42L32 44L34 44L34 42 Z M 36 42L36 44L38 44L38 42 Z M 40 42L40 44L42 44L42 42Z" />
							</svg>
							CIN
						</span>
						<p className="location_p">U01119DL2022PTC393831</p>
					</div>
					<Link
						rel="canonical"
						href="https://in.linkedin.com/company/zevoindia"
					>
						<Image
							className="handle_image"
							src={linkedIn}
							width={30}
							height={30}
							alt="linkedin"
						/>
					</Link>
					<Link
						rel="canonical"
						href="https://www.instagram.com/zevoindia/"
					>
						<Image
							className="handle_image"
							src={insta}
							width={30}
							height={30}
							alt="insta"
						/>
					</Link>
				</div>
				<div className="copyright_para">
					© 2024 ZEVO, All Rights Reserved
				</div>
			</div>
		</footer>
	);
};

export default Footer;
